import React from "react"
import { graphql } from "gatsby"
import Layout from "../layouts/en"
import NewsPageBase from "../templates/news"

class NewsPage extends React.Component {
  render() {
    return (
      <Layout>
        <NewsPageBase data={this.props.data} locale="en" />
      </Layout>
    )
  }
}

export default NewsPage

export const query = graphql`
  query NewsEnQuery {
    allSanityPost(
      sort: { order: DESC, fields: publishedAt }
      filter: { title: { en: { ne: null } } }
    ) {
      edges {
        node {
          id
          title {
            content: en
          }
          slug {
            current
          }
          link
          has_page
          excerpt {
            content: en
          }
          mainImage {
            asset {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          publishedAt(locale: "en", fromNow: true)
        }
      }
    }
  }
`
